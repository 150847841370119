import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileSchema } from '../../../validationSchema/profileSchema';
import { FormInput } from '../../../../src/utility/FormInput';
import { auth, authConstant } from '../../../actions/auth.action';
import Message from '../../../components/Message/Message';
import Sidebar from '../../../utility/Sidebar';
import './style.css';

const schema = profileSchema;

function ProfileEdit() {
    const authState = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });

    useEffect(() => {
        document.title = 'Shopthumb | Profile';
        if (!authState.userDetails.id) {
            navigate("/");
        }
    }, [authState, navigate]);

    const logOut = () => {
        dispatch(auth.signOut({ Token: authState.userDetails.token }));
    };

    const onSubmit = (data) => {
        dispatch({ type: authConstant.UPDATE_REQUEST });
        dispatch(auth.updateProfile(data, { Token: authState.userDetails.token }));
        // setIsEditing(false); // Disable editing after update
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
        const loginPasswordEl = document.getElementById('password');
        if (loginPasswordEl) {
            loginPasswordEl.type = showPassword ? 'text' : 'password';
        }
    };
    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <div className="container mb-5">
                <div className="row">
                    <Sidebar authState={authState} logOut={logOut} component='profile' />

                    <div className="col-md-10">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade bg-white show active p-4" id="v-pills-acc" role="tabpanel" aria-labelledby="v-pills-acc-tab">
                                {authState.error.type === 'profile' && <Message />}

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <h4 className="text-center mb-4">YOUR INFORMATION</h4>
                                        <div style={{marginLeft: isMobile ? '25%': '37%', width: isMobile? '67%':'50%'}}>
                                            <p><strong>Name:</strong> {authState.userDetails.first_name} {authState.userDetails.last_name}</p>
                                            <p><strong>Email:</strong> {authState.userDetails.email}</p>
                                            <p><strong>Password:</strong> {"*".repeat(authState.userDetails.password.length)}</p>
                                        </div>
                                        <button type="button" className="btn edit-btn" onClick={() => navigate('/profile')} style={{ width: '36%', border: '2px solid grey',marginTop: '10px', marginLeft: '34%'}}>
                                            Edit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-cashback" role="tabpanel" aria-labelledby="v-pills-cashback-tab">
                                <h4 class=" mb-4">Cashback</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-looks" role="tabpanel" aria-labelledby="v-pills-looks-tab">
                                <h4 class=" mb-4">Looks</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-closet" role="tabpanel" aria-labelledby="v-pills-closet-tab">
                                <h4 class=" mb-4">Closet</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileEdit;
